import Vue from 'vue';
import { API_BASE_URL } from '@/config';
export default {
  login (query, data) {
    let route = '';
    if (query) {
      route = 'v1/login?' + query;
    } else {
      route = 'v1/login';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_machinelearning_data_get (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/machinelearning/data/get?' + query;
    } else {
      route = 'v1/wanalyzer/machinelearning/data/get';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  },
  wanalyzer_machinelearning_predict (query, data) {
    let route = '';
    if (query) {
      route = 'v1/wanalyzer/machinelearning/predict?' + query;
    } else {
      route = 'v1/wanalyzer/machinelearning/predict';
    }
    return Vue.http.post(API_BASE_URL + route, data);
  }
};
