<template>
  <div>
    <h3> {{ p_pageInfo.caption }} </h3>
    <!-- <h6> <img src="@/icon/3037139.png" style="width: 2em;"> ***</h6> -->
    <template v-for="(page_info, page_info_index) in p_pageInfo.list">
      <b-row v-if="page_info.label">
        <b-col cols="1" style="text-align: right;">
          <img src="@/icon/1661274.png" style="width: 1.5em;">
        </b-col>
        <b-col cols="11">
          <div>{{ page_info.label }}</div>
        </b-col>
      </b-row>
      <b-row v-if="page_info.code">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <h6 class="code-text"> {{ page_info.code }} </h6>
        </b-col>
      </b-row>
      <b-row v-if="page_info.code_html">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <div class="code-text" v-html="page_info.code_html"></div>
        </b-col>
      </b-row>
      <b-row v-if="page_info.link">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <h6>
              <a :href="page_info.link" target="_blank">{{ page_info.link}}</a>
          </h6>
        </b-col>
      </b-row>
      <b-row v-if="page_info.iframe">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <iframe :src="page_info.iframe" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen=""></iframe>
        </b-col>
      </b-row>
      <b-row v-if="page_info.mail">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <cite :title="page_info.mail"><a :href="'mailto:' + page_info.mail + '?Subject='" target="_top">{{ page_info.mail }}</a></cite>
        </b-col>
      </b-row>
      <b-row v-if="page_info.base64">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <img :src="page_info.base64" :style="StoreDevice.isMobile ? 'width: 100%' : 'width: 60%'">
        </b-col>
      </b-row>
      <b-row v-if="page_info.img_link">
        <b-col cols="1" style="text-align: right;">
        </b-col>
        <b-col cols="11">
          <img :src="page_info.img_link" :style="StoreDevice.isMobile ? 'width: 100%' : 'width: 60%'">
        </b-col>
      </b-row>
      <template v-if="page_info.machine_learning_id && d_machineLearningList[page_info.machine_learning_id] != undefined && d_machineLearningList[page_info.machine_learning_id].analyze_results != undefined">
        <machine-learning-result :p_machineLearningResult="d_machineLearningList[page_info.machine_learning_id].analyze_results" :p_machineLearningId="page_info.machine_learning_id"></machine-learning-result>
      </template>
    </template>
  </div>
</template>

<script>
import {
  default as MachineLearningResult
} from "@/components/widgets/MachineLearningResult";
import WanalyzerService from '@/services/wanalyzer';
import { mapGetters } from 'vuex';
export default {
  name: 'PageInfo',
  components: {
    MachineLearningResult
  },
  computed: {
    ...mapGetters({
      StoreDevice: 'StoreDevice',
      StoreUser: 'StoreUser',
      StoreLoading: 'StoreLoading',
      StoreModal: 'StoreModal',
      StoreLang: 'StoreLang',
    })
  },
  props: {
    p_pageInfo: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_machineLearningList: {}
    }
  },
  created: function () {
    // console.log(this.p_pageInfo);
    if (this.p_pageInfo && this.p_pageInfo.list && this.p_pageInfo.list.length > 0) {
      for (let i in this.p_pageInfo.list) {
        if (this.p_pageInfo.list[i].machine_learning_id) {
          this.f_wanalyzerMachinelearningDataGet(this.p_pageInfo.list[i].machine_learning_id, parseInt(i));
        }
      }
    }
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_wanalyzerMachinelearningDataGet: function (machine_learning_id, page_item_index) {
      let query = 'machine_learning_id=' + machine_learning_id;
      let data = {};
      WanalyzerService.wanalyzer_machinelearning_data_get(query, data)
        .then(resp => {
          if (resp.data.status_code === "50000") {
            this.d_machineLearningList[machine_learning_id] = resp.data.machine_learning;
            this.$forceUpdate();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

