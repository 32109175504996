<template>
  <div>
    <template v-if="p_machineLearningResult && p_machineLearningResult.length > 0">
      <template v-for="(result_item, result_item_index) in p_machineLearningResult">
        <template v-for="(ml, ml_index) in result_item.machine_learning">
          <b-row style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              Makine öğrenmesi modeli {{ ml.type.replace('_', ' ') }}, <small>{{ ml.lib }}</small>
            </b-col>
          </b-row>
          <b-row style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <strong>Girdi Parametreleri ile tahminleme</strong>
            </b-col>
          </b-row>
          <template v-for="(inp, inp_index) in ml.input_variable_details">
            <b-row style="padding-top: 10px;">
              <b-col cols="1" style="text-align: right;">
                <img src="@/icon/1661274.png" style="width: 1.5em;">
              </b-col>
              <b-col cols="11">
                <b-row>
                  <b-col sm="12" md="6">
                    {{ inp.label }}
                  </b-col>
                  <b-col sm="12" md="6">
                    <template v-if="inp.type">
                      <template v-if="inp.type === 'categorical'">
                        <b-form-select v-model="inp.selected">
                          <option v-for="(op, op_ind) in inp.options" :value="op.value">
                            {{ op.label }}
                          </option>
                        </b-form-select>
                      </template>
                      <template v-else-if="inp.type === 'numeric'">
                        <b-form-input type="number" v-model="inp.selected"></b-form-input>
                      </template>
                    </template>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
          <b-row style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">
                  Analiz edilen parametre (Çıktı) : {{ ml.output_variable_details.label }}
                </b-col>
                <b-col cols="3">
                  <strong v-if="ml.predict_result" style="color: green;"> Sonuç : {{ ml.predict_result.predict }} </strong>
                </b-col>
                <b-col cols="3">
                  <b-button class="pull-right" variant="success" size="md" @click="f_wanalyzerMachinelearningAnalyze(result_item_index, ml_index)">analiz yap</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <strong>Model Özellikleri</strong>
            </b-col>
          </b-row>
          <b-row v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">Confusion Matrix</b-col>
          </b-row>
          <b-row v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <table style="width: 100%;">
                <tr>
                  <th style="text-align: center;"></th>
                  <th colspan="1" style="text-align: center;"></th>
                  <th colspan="2" style="text-align: center;">Gerçek Değerler</th>
                  <th style="text-align: center;"></th>
                </tr>
                <tr>
                  <th style="text-align: center;"></th>
                  <th style="text-align: center;"></th>
                  <th style="text-align: center; color: green;">Pozitif</th>
                  <th style="text-align: center; color: red;">Negatif</th>
                  <th style="text-align: center;">Total</th>
                </tr>
                <tr>
                  <td rowspan="2" style="text-align: center;"><strong>Tahmin Değerleri</strong></td>
                  <td style="text-align: center; color: green;"><strong>Pozitif</strong></td>
                  <td style="text-align: center;" class="confusion-matrix" title="Doğru Pozitif: Pozitif olana model de pozitif demiştir. Doğru tahmin.">{{ ml.confusion_matrix[0][0] }}</td>
                  <td style="text-align: center;" class="confusion-matrix" title="Yanlış Pozitif: Aslında negatif olana model pozitif demiştir. Yanlış tahmin">{{ ml.confusion_matrix[0][1] }}</td>
                  <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[0][1]) }}</td>
                </tr>
                <tr>
                  <td style="text-align: center; color: red;"><strong>Negatif</strong></td>
                  <td style="text-align: center;" class="confusion-matrix" title="Yanlış Negatif: Aslında pozitif olana model negatif demiştir. Yanlış tahmin">{{ ml.confusion_matrix[1][0] }}</td>
                  <td style="text-align: center;" class="confusion-matrix" title="Doğru Negatif: Negatif olana model de negatif demiştir. Doğru tahmin">{{ ml.confusion_matrix[1][1] }}</td>
                  <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[1][0]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                </tr>
                <tr>
                  <td style="text-align: center;"></td>
                  <td style="text-align: center;">Total</td>
                  <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[1][0]) }}</td>
                  <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][1]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                  <td style="text-align: center;">{{ parseInt(ml.confusion_matrix[0][0]) + parseInt(ml.confusion_matrix[0][1]) + parseInt(ml.confusion_matrix[1][0]) + parseInt(ml.confusion_matrix[1][1]) }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row v-if="ml.cluster_centers_ && ml.cluster_centers_.length > 0" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6"> Küme merkezleri </b-col>
                <b-col cols="6">
                  <table style="width: 100%;">
                    <tr>
                      <th></th>
                      <th v-for="(input_var, input_var_ind) in ml.input_variables" class="confusion-matrix">
                        <span :title="input_var">
                            {{ input_var.length > 10 ? input_var.substring(0, 10) + '...' : input_var }}
                          </span>
                      </th>
                    </tr>
                    <tr v-for="(rw_cluster, rw_cluster_ind) in ml.cluster_centers_">
                      <td>{{ rw_cluster_ind + 1 }} . küme</td>
                      <td v-for="(col_cluster, col_cluster_ind) in rw_cluster">
                        <span :title="col_cluster">{{ parseFloat(col_cluster).toFixed(2) }}</span>
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.test_size !== undefined" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">Test Boyutu</b-col>
                <b-col cols="6">{{ ml.test_size }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.random_state !== undefined" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">Random State</b-col>
                <b-col cols="6">{{ ml.random_state }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.kernel_type !== undefined" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">Kernel Tipi</b-col>
                <b-col cols="6">{{ ml.kernel_type }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.init !== undefined" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">Init</b-col>
                <b-col cols="6">{{ ml.init }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.n_clusters !== undefined" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">n_clusters</b-col>
                <b-col cols="6">{{ ml.n_clusters }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.accuracy !== undefined" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">Accuracy (doğruluk)</b-col>
                <b-col cols="6">% {{ (ml.accuracy * 100).toFixed(0) }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">Precision (Kesinlik)</b-col>
                <b-col cols="6">{{ (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))).toFixed(2) }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">Recall (Duyarlılık)</b-col>
                <b-col cols="6">
                  {{ (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))).toFixed(2) }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.confusion_matrix && ml.confusion_matrix.length > 0" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6">F1 skoru</b-col>
                <b-col cols="6">
                  {{ ( 2 * ( (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))) * (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))) ) / ( (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[0][1]))) + (parseFloat(ml.confusion_matrix[0][0]) / (parseFloat(ml.confusion_matrix[0][0]) + parseFloat(ml.confusion_matrix[1][0]))) ) ).toFixed(2) }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="ml.roc && ml.roc.auc" style="padding-top: 10px;">
            <b-col cols="1" style="text-align: right;">
              <img src="@/icon/1661274.png" style="width: 1.5em;">
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col cols="6"> ROC (auc) </b-col>
                <b-col cols="6">{{ (ml.roc.auc).toFixed(2) }}</b-col>
              </b-row>
            </b-col>
          </b-row>
          <template v-for="(fig, fig_ind) in ml.figure_list">
            <b-row style="padding-top: 10px;">
              <b-col cols="1" style="text-align: right;">
                <img src="@/icon/1661274.png" style="width: 1.5em;">
              </b-col>
              <b-col cols="11">
                <img :src="fig.figure" style="width: 50%;">
              </b-col>
            </b-row>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import WanalyzerService from '@/services/wanalyzer';
export default {
  name: 'MachineLearningResult',
  components: {},
  props: {
    p_machineLearningId: {
      type: String,
      required: true
    },
    p_machineLearningResult: {
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      d_selectedMachineLearningdata: { 'show': false, 'data': {} },
    }
  },
  computed: {},
  methods: {
    f_wanalyzerMachinelearningAnalyze: function (analyze_index, ml_index) {
      // console.log('this.p_machineLearningResult[analyze_index].machine_learning[ml_index].input_variable_details : ', this.p_machineLearningResult[analyze_index].machine_learning[ml_index].input_variable_details);
      this.p_machineLearningResult[analyze_index].machine_learning[ml_index].predict_result = '';
      this.$forceUpdate();
      let query = 'machine_learning_id=' + this.p_machineLearningId;
      query += '&analyze_result_index=' + this.p_machineLearningResult[analyze_index].ar_index.toString();
      query += '&machine_learning_index=' + this.p_machineLearningResult[analyze_index].machine_learning[ml_index].ml_index.toString();
      let data = {
        'input_list': []
      };
      let is_all_input_completed = true;
      for (let i in this.p_machineLearningResult[analyze_index].machine_learning[ml_index].input_variable_details) {
        let input_data = this.p_machineLearningResult[analyze_index].machine_learning[ml_index].input_variable_details[i];
        if (input_data.selected === undefined || input_data.selected === '') {
          is_all_input_completed = false;
          break;
        }
        let new_inp = {
          'value': input_data.selected,
          'label': input_data.label,
          'column_index': input_data.column_index
        }
        data.input_list.push(new_inp);
      }
      if (is_all_input_completed) {
        WanalyzerService.wanalyzer_machinelearning_predict(query, data)
          .then(resp => {
            if (resp.data.status_code === "50000") {
              this.p_machineLearningResult[analyze_index].machine_learning[ml_index].predict_result = resp.data.predict;
              this.$forceUpdate();
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      } else {
        alert('Please completed inputs');
      }
    },
  }
}

</script>

